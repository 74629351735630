import React from "react"

import "../css/index.css"
import 'bootstrap/dist/css/bootstrap.css';

const services = () => (

    <div className="Main">
        <div className="blog-page-area blog-page-fullwidth section-padding">
   <div className="container">
       <div className="row">
           <div className="col-lg-12 col-md-12 col-12 text-center">
               <div className="blog-left-bar blog-left-bar-2 blog-left-bar-3">
                   <div className="blog-item">
                   <h2>Os nossos serviços</h2>
                       <div className="blog-content-2 mt-5 text-justify">
                           <h4>Escrituras Públicas</h4>
                                <p>- Compra e Venda
                                <br/>
                                - Permuta
                                <br/>
                                - Doação
                                <br></br>
                                - Habilitação de Herdeiros e de Legatários
                                <br></br>
                                - Partilha por morte
                                <br></br>
                                - Partilha por divórcio
                                <br></br>
                                - Repúdio
                                <br></br>
                                - Justificação
                                <br></br>
                                - Mútuo
                                <br></br>
                                - Hipoteca
                                <br></br>
                                - Propriedade Horizontal
                                <br></br>
                                - Constituição e Dissolução de Sociedades
                                <br></br>
                                - Cessões de Quotas
                                <br></br>
                                - Aumentos de capital
                                <br></br>
                                - Constituição de Associações e alterações de estatutos</p>
                                <h4>Testamentos</h4>
                                <br></br>
                                <h4>Registos</h4>
                                    <p>- Predial
                                    <br></br>
                                    - Comercial</p>
                                <h4>Procurações</h4>
                                <br></br>
                                <h4>Reconhecimentos de assinaturas</h4>
                                <br></br>
                                <h4>Autenticação de documentos</h4>
                                <br></br>
                                <h4>Traduções e Certificação de Traduções</h4>
                                <br></br>
                                <h4>Arquivos</h4>
                                <br></br>
                                    <p><b>- Cartório Notarial de Ana Paula Pinto Alves</b></p>
                                    <p><b>- Cartório Notarial de Pombal</b></p>
                                    <p><b>- Cartório Notarial de Paula Cristina Rocha Teixeira de Oliveira Sobreiros</b></p>
                        </div>
                   </div>
                    </div>
                </div>
             </div>
       </div>
   </div>
    </div>
)

export default services
